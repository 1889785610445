import { useEffect } from "react";

const useFocusOnIframe = ({ iframeRef }) => {
  useEffect(() => {
    const handleFocus = () => {
      if (iframeRef.current && document.activeElement !== iframeRef.current) {
        iframeRef.current.focus();
      }
    };

    const handleBodyClick = () => {
      handleFocus();
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [iframeRef]);

  useEffect(() => {
    const handleIframeFocus = () => {
      setTimeout(() => {
        if (iframeRef.current && document.activeElement !== iframeRef.current) {
          iframeRef.current.focus();
        }
      }, 200);
    };
    window.addEventListener("focus", handleIframeFocus);

    return () => {
      window.removeEventListener("focus", handleIframeFocus);
    };
  }, []);
};

export default useFocusOnIframe;
