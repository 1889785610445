import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "utils/helpers/api.helpers";
import { setBestRegions } from "utils/helpers/pinger.helpers";
import i18next from "i18next";
import StreamsDarkLogo from "assets/images/streams-dark-logo.png";
import StreamsLightLogo from "assets/images/streams-light-logo.png";
import {
  API_ROUTES,
  ERROR_CODES,
  INITIALIZING_STREAM_TEXT,
} from "utils/constants";
import { createConsumer } from "@rails/actioncable";
import useWindowPostMessages from "hooks/useWindowPostMessages";
import axios from "axios";
import { isMobile } from "utils/helpers/browser.helpers";
import { nth } from "utils/helpers/generic.helpers";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  saveItemToLocalStorage,
} from "utils/helpers/storage.helpers";
import useSessionStorage from "hooks/useSessionStorage";
import useLocalStorage from "hooks/useLocalStorage";
import useFocusOnIframe from "hooks/useFocusOnIframe";

import Background from "components/Background/Background.component";
import Initializing from "components/Initializing/Initializing.component";
import Error from "../../components/Error/Error.component";
import EmailCollect from "components/EmailCollect/EmailCollect.component";
import Password from "components/Password/Password.component";

import "./Streams.styles.scss";

const Streams = () => {
  let { id: streamUID } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [region, setRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [latencyTolerance, setLatencyTolerance] = useState(50);
  const [connectionLink, setConnectionLink] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [userID, setUserID] = useState(null);
  const [initializingMessage, setInitializingMessage] = useState(
    INITIALIZING_STREAM_TEXT
  );
  const [connected, setConnected] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const [waitListedUserId, setWaitListedUserId] = useSessionStorage(
    "waitListedUserId",
    null
  );
  const [vagonID] = useLocalStorage("vagonID", Math.random().toString(36));

  let parentStreamUIDRef = useRef(null);

  const iframeRef = useRef(null);
  const loaderRef = useRef(null);
  const bgContainerRef = useRef(null);
  const actionCableRef = useRef(null);
  const broadcastChannel = new BroadcastChannel("streamQueueChannel");
  const websocketTimeout = useRef(null);

  useWindowPostMessages({ iframeRef, setErrorCode });
  useFocusOnIframe({ iframeRef });

  const localConnectionLink = () =>
    getItemFromLocalStorage(
      `${parentStreamUIDRef.current || streamUID}-connection-link`
    );

  // Safari back-forward cache fix
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  const {
    api: getStreamAPI,
    data: streamData,
    isLoading: getStreamLoading,
  } = useAPI({
    endpoint: API_ROUTES.getStream(streamUID),
    onSuccess: (data) => {
      const {
        parent_stream_uid: parentStreamUID,
        texts,
        status,
      } = data.stream.attributes;

      if (parentStreamUID) {
        parentStreamUIDRef.current = parentStreamUID;
      }

      setInitializingMessage(texts.initializing_text);
      setLatencyTolerance(data.latency_tolerance);

      const streamActive = status === "active";
      if (!streamActive) {
        setErrorCode(4002);
        return;
      }
      if (localConnectionLink()) {
        checkConnectionLink();
        setErrorCode(null);
        return;
      }
      setBestRegions({
        connectStreamData: data.stream,
        setRegion,
        setRegions,
        latencyTolerance,
      });
      setErrorCode(null);
    },
    onError: (error) => {
      if (error.response?.data?.error_code) {
        setErrorCode(error.response.data.error_code);
      } else {
        setErrorCode(ERROR_CODES.streamNotFound);
      }
      setWaitListedUserId(null);
    },
  });

  useEffect(() => {
    getStreamAPI();

    broadcastChannel.onmessage = (message) => {
      if (
        actionCableRef.current !== null &&
        message.data === "disconnectOtherTabs"
      ) {
        actionCableRef.current.disconnect();
        actionCableRef.current = null;
        setErrorCode(ERROR_CODES.duplicatedTab);
      }
    };

    return () => {
      actionCableRef.current?.disconnect();
    };
  }, []);

  const { api: connectStreamAPI, isLoading: connectStreamLoading } = useAPI({
    endpoint: API_ROUTES.connectStream,
    type: "post",
    onSuccess: (data) => {
      handleConnectStreamSuccess(data);
      setErrorCode(null);
      if (initializingMessage !== initializingText) {
        setInitializingMessage(initializingText);
      }
    },
    onError: (error) => {
      const { error_code, user_id } = error.response?.data || {};
      if (error_code) {
        setErrorCode(error_code);
        return;
      }
      if (user_id) {
        connectSocket(user_id);
        setWaitListedUserId(user_id);
        return;
      }
      setErrorCode(ERROR_CODES.streamCapacityFull);
    },
  });

  const connectSocket = (user_id) => {
    actionCableRef.current = createConsumer(
      `${process.env.REACT_APP_WEBSOCKET_BASE_URL}?id=${user_id}`
    );

    actionCableRef.current.subscriptions.create(
      { channel: "ConnectStreamChannel" },
      {
        received: (message) => {
          clearTimeout(websocketTimeout.current);

          if (message.error_code) {
            setErrorCode(message.error_code);
            return;
          }

          if (message.state === "waiting") {
            setInitializingMessage(
              i18next.t("queueMessage", {
                interpolation: { escapeValue: false },
                queueToken: nth(message.queue_token),
                message: texts?.queue_text,
              })
            );
            return;
          }

          if (message.connect_stream_data) {
            actionCableRef.current.disconnect();
            actionCableRef.current = null;
            broadcastChannel.postMessage("disconnectOtherTabs");
            handleConnectStreamSuccess(message.connect_stream_data);
            setErrorCode(null);
            if (initializingMessage !== initializingText) {
              setInitializingMessage(initializingText);
            }
          }
        },
      }
    );
  };

  const handleConnectStreamSuccess = (data) => {
    setConnectionLink(data.connection_link);
    saveItemToLocalStorage(
      `${parentStreamUIDRef.current || streamUID}-connection-link`,
      data.connection_link
    );

    saveItemToLocalStorage(
      `${parentStreamUIDRef.current || streamUID}-machine-uid`,
      data.machine.attributes.uid
    );

    setTimeout(() => {
      iframeRef.current.style.opacity = "1";
      loaderRef.current.style.opacity = "0.0";
      setTimeout(() => {
        iframeRef.current.focus();
        loaderRef.current.style.display = "none";
        setConnected(true);
      }, 500);
    }, 3000);
  };

  // Stream related data
  const stream = streamData?.stream;
  const texts = stream?.attributes?.texts;
  const collectInfo = stream?.attributes?.collect_info;
  const darkMode = stream?.attributes?.dark_mode;
  const enterprise = stream?.attributes?.application?.attributes?.enterprise;
  const bannerURL = stream?.attributes?.application?.attributes?.banner_url;
  const logoURL = stream?.attributes?.application?.attributes?.logo_url;
  const passwordProtected = stream?.attributes?.password_protection;
  const scaledResolution = stream?.attributes?.resolution
    ? stream?.attributes?.resolution === "res_scale"
    : true;
  const initializingText = texts?.initializing_text;
  // connect stream when region stream or userID changed
  useEffect(() => {
    if (stream && region && !connectionLink && !connectStreamLoading) {
      if (collectInfo && !userID) return;
      if (passwordProtected && !authenticated) return;
      connectStreamAPI({
        id: streamUID,
        region: region,
        regions: regions,
        user_id: userID,
        wait_listed_user_id: waitListedUserId,
        vagon_id: vagonID,
      });
    }
  }, [region, stream, userID, authenticated]);

  // Set connected to false on error
  useEffect(() => {
    setConnected(false);
  }, [errorCode]);

  // Check local connection link and connect it if it is valid
  const checkConnectionLink = () => {
    const machineUID = getItemFromLocalStorage(
      `${parentStreamUIDRef.current || streamUID}-machine-uid`
    );

    if (!machineUID) {
      cleanLocalStorage();
      return;
    }

    let url = `${process.env.REACT_APP_VAGON_API_BASE_URL}/app-stream-sessions/${machineUID}`;
    if (parentStreamUIDRef.current) {
      url = url + `?child_stream_uid=${streamUID}`;
    }

    axios
      .get(url)
      .then((res) => {
        const connectionStatus = res.data.attributes.connection_status;
        if (
          connectionStatus === "proxy_rule_migration" ||
          connectionStatus === "cleaning"
        ) {
          cleanLocalStorage();
        } else {
          setConnectionLink(localConnectionLink());
          setErrorCode(null);
          setTimeout(() => {
            iframeRef.current.style.opacity = "1";
            loaderRef.current.style.opacity = "0";

            setTimeout(() => {
              iframeRef.current.focus();
              loaderRef.current.style.display = "none";
              setConnected(true);
            }, 500);
          }, 3000);
        }
      })
      .catch(() => {
        cleanLocalStorage();
      });
  };

  const focusIframeEvent = () => {
    iframeRef?.current?.contentWindow?.focus();
  };

  useEffect(() => {
    window.addEventListener("focus", focusIframeEvent);

    return () => {
      window.removeEventListener("focus", focusIframeEvent);
    };
  }, [connectionLink]);

  const cleanLocalStorage = () => {
    setConnectionLink(null);
    if (parentStreamUIDRef.current) {
      removeItemFromLocalStorage(
        `${parentStreamUIDRef.current}-connection-link`
      );
      removeItemFromLocalStorage(`${parentStreamUIDRef.current}-machine-uid`);
    }
    removeItemFromLocalStorage(`${streamUID}-connection-link`);
    removeItemFromLocalStorage(`${streamUID}-machine-uid`);
    setErrorCode(null);
    getStreamAPI();
  };

  const retryConnection = () => {
    setInitializingMessage(initializingText);

    if (localConnectionLink()) {
      checkConnectionLink();
    } else {
      getStreamAPI();
    }
  };

  const checkLaunchFlags = () => {
    const launchFlags = urlSearchParams.get("launchFlags");
    const localLaunchFlags = getItemFromLocalStorage("launchFlags");

    if (launchFlags) {
      saveItemToLocalStorage("launchFlags", launchFlags, true);
    } else {
      removeItemFromLocalStorage("launchFlags");
    }

    if (localLaunchFlags && localLaunchFlags !== launchFlags) {
      return true;
    }

    return false;
  };

  const streamURL = useMemo(() => {
    const newSession = urlSearchParams.get("newSession");
    const launchFlags = urlSearchParams.get("launchFlags");
    let queryParams = [];

    const launchFlagsChanged = checkLaunchFlags();

    if (newSession === "true" || launchFlagsChanged) {
      queryParams.push("newSession=true");
    }
    if (launchFlags) {
      queryParams.push(`launchFlags=${encodeURIComponent(launchFlags)}`);
    }

    return queryParams.length > 0
      ? `${connectionLink}?${queryParams.join("&")}`
      : connectionLink;
  }, [connectionLink]);

  const logo = () => {
    if (enterprise && logoURL) {
      return logoURL;
    }
    if (darkMode) return StreamsDarkLogo;
    return StreamsLightLogo;
  };

  if (!errorCode && getStreamLoading) {
    return <div className="loading-container" />;
  }

  if (errorCode) {
    return (
      <Background
        disableScale={isMobile && !getStreamLoading}
        scaledResolution={scaledResolution}
        bgContainerRef={bgContainerRef}
        darkMode={darkMode}
        bannerURL={bannerURL}
        enterprise={enterprise}
      >
        {getStreamLoading ? (
          <Initializing
            text={initializingMessage}
            logo={logo()}
            loaderRef={loaderRef}
            darkMode={darkMode}
            stream={stream}
          />
        ) : (
          <Error
            logo={logo()}
            errorCode={errorCode}
            action={retryConnection}
            darkMode={darkMode}
            texts={texts}
            actionText="Retry Connection"
          />
        )}
      </Background>
    );
  }

  if (
    collectInfo &&
    !connectionLink &&
    !userID &&
    !getStreamLoading &&
    !localConnectionLink()
  ) {
    return (
      <Background
        scaledResolution={scaledResolution}
        bgContainerRef={bgContainerRef}
        darkMode={darkMode}
        bannerURL={bannerURL}
        enterprise={enterprise}
      >
        <EmailCollect
          setUserID={setUserID}
          streamUID={streamUID}
          logo={logo()}
          darkMode={darkMode}
          setErrorCode={setErrorCode}
        />
      </Background>
    );
  }

  if (
    passwordProtected &&
    !connectionLink &&
    !authenticated &&
    !getStreamLoading &&
    !localConnectionLink()
  ) {
    return (
      <Background
        scaledResolution={scaledResolution}
        bgContainerRef={bgContainerRef}
        darkMode={darkMode}
        bannerURL={bannerURL}
        enterprise={enterprise}
      >
        <Password
          streamUID={streamUID}
          logo={logo()}
          darkMode={darkMode}
          setErrorCode={setErrorCode}
          setAuthenticated={setAuthenticated}
        />
      </Background>
    );
  }

  return (
    <>
      <Background
        scaledResolution={scaledResolution}
        bgContainerRef={bgContainerRef}
        darkMode={darkMode}
        bannerURL={bannerURL}
        enterprise={enterprise}
        connected={connected}
      >
        <Initializing
          text={initializingMessage}
          logo={logo()}
          loaderRef={loaderRef}
          darkMode={darkMode}
          stream={stream}
        />
        <iframe
          title="vg-stream-iframe"
          id="vg-stream-iframe"
          ref={iframeRef}
          src={streamURL}
          allowFullScreen={true}
          webkitallowfullscreen="true"
          allow="microphone  *; clipboard-read *; clipboard-write *; encrypted-media *;"
        ></iframe>
      </Background>
    </>
  );
};

export default Streams;
